// div.home-col {
//     padding-top: 4vw;
//     border-radius: 25%;
//     padding-bottom: 3vw;
// }

// p {
// line-height: 2vw;
// }
  

.hero_container {
    background: url('../../siteImages/website_pattern.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
}

//     .inner_container{
//         display:flex; 
//         flex-direction:row; 
//         justify-content:center; 
//         align-items:center;

//         .text_container{
//             display:flex;
//             flex-direction:column;
//             justify-content:center;
//             align-items:center;
//             position:absolute;
//             right:550px;

//             .title_text{
//                 font-size: 56;
//                 width:70%;
//                 align-self: flex-end;
//                 margin-right: 57px;
//             }

//             .description_text{
//                 font-size: 20;
//                 width:50%;
//                 align-self: center;
//             }
//         }

.hero_image{
    width:629px;
    height:414px;
    border-radius:207px;
    margin-left:500px;
}
//     }
// }





.info_section{
  background-color: #DEF0D8;
  .info_nav {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 20px;
        font-size: 25px;

        .info_nav_text {
            padding: 20px;
            cursor: pointer;
        }

        .active{
            border-bottom: solid 1.5px;
        }
    }

    .info_content{

        .info_content_left{

            .info_content_title{
                font-size: 56px;
                width: 100%;
            }

            .info_content_answer{
                margin-top: 40px;
            }

            .info_content_description{
                width: 100%;
                font-size: 18px;
            }
        }
    }
}

.decisions_section{
    min-height: 60vh;
    background-color: #FBF6ED;
    display: flex !important;
    justify-content: center;
    align-items: center;

    .inner_container{
        display:flex; 
        flex-direction:row; 
        justify-content:center; 
        align-items:center;

        .text_container{
            display:flex;
            flex-direction:column;
            justify-content:center;
            align-items:flex-start;

            .title_text{
                font-size: 56;
            }

            .description_text{
                font-size: 20;
            }
              
        }

        
    }
}

.hero_image_decision{
    width:629px;
    height:414px;
    border-radius:207px;
}

@media screen and (max-width: 769px) {
    .hero_image_decision{
        width:429px;
        height:314px;
        border-radius:207px;
    }

    .hero_image{
        width:429px;
        height:314px;
        border-radius:207px;
    }
}